<template>
<svg version="1.1" id="logo-fitphysiotitlis" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 149 52.6" style="enable-background:new 0 0 149 52.6;" xml:space="preserve">
<polygon class="st0" points="15.5,27.6 5.5,27.6 4.5,35.8 13.1,35.8 12.7,38.2 4.2,38.2 3,48 0,48 2.8,25.2 15.8,25.2 "/>
<polygon class="st0" points="20.1,48 17.1,48 19.9,25.2 22.9,25.2 "/>
<polygon class="st0" points="42.8,25.2 42.5,27.7 35.7,27.7 33.2,48 30.3,48 32.8,27.7 26,27.7 26.3,25.2 "/>
<polygon class="st0" points="86.1,27.6 76.1,27.6 75.2,35.3 83.3,35.3 83,37.7 74.9,37.7 73.9,45.6 83.9,45.6 83.6,48 70.6,48 
73.4,25.2 86.4,25.2 "/>
<path class="st0" d="M100.8,28.7c-0.1,0.1-0.2,0.3-0.3,0.3c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.4-0.1-0.6-0.3
c-0.2-0.2-0.5-0.4-0.8-0.6c-0.3-0.2-0.7-0.4-1.1-0.6c-0.4-0.2-1-0.3-1.7-0.3c-0.7,0-1.3,0.1-1.8,0.3c-0.5,0.2-1,0.5-1.3,0.9
c-0.4,0.4-0.6,0.8-0.8,1.3c-0.2,0.5-0.3,1-0.3,1.6c0,0.5,0.1,1,0.4,1.4c0.3,0.4,0.6,0.7,1,1c0.4,0.3,0.9,0.5,1.4,0.7
c0.5,0.2,1.1,0.4,1.6,0.6c0.5,0.2,1.1,0.5,1.6,0.7c0.5,0.3,1,0.6,1.4,1c0.4,0.4,0.7,0.9,1,1.4c0.3,0.6,0.4,1.2,0.4,2
c0,1.1-0.2,2.1-0.6,3.1c-0.4,1-0.9,1.8-1.6,2.5c-0.7,0.7-1.5,1.3-2.5,1.7c-1,0.4-2.1,0.6-3.3,0.6c-1.4,0-2.6-0.3-3.6-0.8
c-1.1-0.5-1.9-1.3-2.6-2.2l1-1.4c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.3-0.1,0.4-0.1c0.2,0,0.4,0.1,0.7,0.4c0.2,0.2,0.5,0.5,0.9,0.8
c0.4,0.3,0.8,0.6,1.3,0.8c0.5,0.2,1.2,0.4,2,0.4c0.7,0,1.4-0.1,2-0.4c0.6-0.2,1.1-0.6,1.5-1c0.4-0.4,0.7-0.9,0.9-1.5
c0.2-0.6,0.3-1.2,0.3-1.9c0-0.6-0.1-1.1-0.4-1.5c-0.3-0.4-0.6-0.7-1-1c-0.4-0.3-0.9-0.5-1.4-0.7c-0.5-0.2-1.1-0.4-1.6-0.6
c-0.6-0.2-1.1-0.4-1.6-0.7c-0.5-0.3-1-0.6-1.4-1c-0.4-0.4-0.8-0.9-1-1.5c-0.3-0.6-0.4-1.3-0.4-2.1c0-0.9,0.2-1.8,0.5-2.7
c0.3-0.9,0.8-1.6,1.5-2.3c0.6-0.7,1.4-1.2,2.3-1.6c0.9-0.4,1.9-0.6,3.1-0.6c1.2,0,2.2,0.2,3.1,0.7c0.9,0.5,1.7,1.1,2.3,1.9
L100.8,28.7z"/>
<path class="st0" d="M116.4,28.7c-0.1,0.1-0.2,0.3-0.3,0.3c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.4-0.1-0.6-0.3
c-0.2-0.2-0.5-0.4-0.8-0.6c-0.3-0.2-0.7-0.4-1.1-0.6c-0.4-0.2-1-0.3-1.7-0.3c-0.7,0-1.3,0.1-1.8,0.3c-0.5,0.2-1,0.5-1.3,0.9
c-0.4,0.4-0.6,0.8-0.8,1.3c-0.2,0.5-0.3,1-0.3,1.6c0,0.5,0.1,1,0.4,1.4c0.3,0.4,0.6,0.7,1,1c0.4,0.3,0.9,0.5,1.4,0.7
c0.5,0.2,1.1,0.4,1.6,0.6c0.6,0.2,1.1,0.5,1.6,0.7c0.5,0.3,1,0.6,1.4,1c0.4,0.4,0.8,0.9,1,1.4c0.3,0.6,0.4,1.2,0.4,2
c0,1.1-0.2,2.1-0.6,3.1c-0.4,1-0.9,1.8-1.6,2.5c-0.7,0.7-1.5,1.3-2.5,1.7c-1,0.4-2.1,0.6-3.3,0.6c-1.4,0-2.6-0.3-3.6-0.8
c-1.1-0.5-1.9-1.3-2.6-2.2l1.1-1.4c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.3-0.1,0.4-0.1c0.2,0,0.4,0.1,0.7,0.4c0.2,0.2,0.5,0.5,0.9,0.8
c0.4,0.3,0.8,0.6,1.3,0.8c0.5,0.2,1.2,0.4,2,0.4c0.7,0,1.4-0.1,2-0.4c0.6-0.2,1.1-0.6,1.5-1c0.4-0.4,0.7-0.9,0.9-1.5
c0.2-0.6,0.3-1.2,0.3-1.9c0-0.6-0.1-1.1-0.4-1.5c-0.3-0.4-0.6-0.7-1-1c-0.4-0.3-0.9-0.5-1.4-0.7c-0.5-0.2-1.1-0.4-1.6-0.6
c-0.6-0.2-1.1-0.4-1.6-0.7c-0.5-0.3-1-0.6-1.4-1c-0.4-0.4-0.8-0.9-1-1.5c-0.3-0.6-0.4-1.3-0.4-2.1c0-0.9,0.2-1.8,0.5-2.7
c0.3-0.9,0.8-1.6,1.5-2.3c0.6-0.7,1.4-1.2,2.3-1.6c0.9-0.4,1.9-0.6,3.1-0.6c1.2,0,2.2,0.2,3.2,0.7c0.9,0.5,1.7,1.1,2.3,1.9
L116.4,28.7z"/>
<path class="st0" d="M61.4,12.8C55,17.9,52,9.7,57.5,5.5c5.4-3.4,8.2,1.8,4.7,6.4L61.4,12.8z"/>
<path class="st0" d="M69.6,16.9C68,20,62.9,33.2,62.2,34.1c-1.8,2.3-5.4-9.6-7.9-8.7c-1.8,0.7-15.2,20.2-16,23.1
c-0.5,1.8-0.4,2.5,0.3,4.1c7.6-3.9,12.2-21.9,15.8-21.4c2.4,0.3,7,11.3,8.6,10c0.4-0.3,10.4-23.7,9.7-25.2c-0.5-1-1.9-0.8-2.6,0.2
L69.6,16.9z"/>
<path class="st0" d="M63,20.1c-3,2.7-8,7.1-11.4,2.4c-1.3-1.8-1.4-9.8-1.9-12.9C49.5,8.4,46-5.9,49.8,2.8c5.1,11.9-1.4,29.9,14.5,11
C68,9.3,74.1,1.9,80.4,4.1c4.2,1.4-3.8,5.3-5.2,6.1C70.1,13.5,67.4,15.9,63,20.1"/>
<polygon class="st0" points="128.5,40.7 128.4,41.8 126.4,41.8 125.7,47.9 124.4,47.9 125.2,41.8 123.2,41.8 123.3,40.7 "/>
<polygon class="st0" points="129.8,47.9 128.6,47.9 129.4,40.7 130.7,40.7 "/>
<polygon class="st0" points="136.9,40.7 136.7,41.8 134.8,41.8 134,47.9 132.7,47.9 133.5,41.8 131.5,41.8 131.6,40.7 "/>
<polygon class="st0" points="138.1,46.9 140.7,46.9 140.6,47.9 136.7,47.9 137.6,40.8 138.9,40.8 "/>
<polygon class="st0" points="142.9,47.9 141.6,47.9 142.5,40.7 143.8,40.7 "/>
<path class="st0" d="M148.6,42c0,0.1-0.1,0.1-0.1,0.1c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.2-0.1-0.2-0.2
c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.5,0.1c-0.1,0.1-0.3,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3
c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.3,0.1,0.5,0.2
c0.2,0.1,0.3,0.1,0.5,0.2c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.2,0.1,0.4,0.1,0.6c0,0.3-0.1,0.7-0.2,1
c-0.1,0.3-0.3,0.6-0.5,0.8c-0.2,0.2-0.5,0.4-0.8,0.6c-0.3,0.1-0.7,0.2-1.1,0.2c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.6-0.2
c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.4-0.4l0.5-0.6c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0.1
c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.4,0
c0.4,0,0.7-0.1,0.9-0.3c0.2-0.2,0.3-0.5,0.3-0.9c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.4-0.2
c-0.2-0.1-0.3-0.1-0.5-0.2c-0.2-0.1-0.3-0.1-0.5-0.2c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.4-0.1-0.7
c0-0.3,0.1-0.6,0.2-0.9s0.3-0.5,0.5-0.8c0.2-0.2,0.5-0.4,0.8-0.5c0.3-0.1,0.6-0.2,1-0.2c0.2,0,0.4,0,0.6,0.1c0.2,0,0.3,0.1,0.5,0.2
c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.3L148.6,42z"/>
</svg>
</template>

